<template>
  <div>
    

   

    <!-- Dashboard -->
    <section @submit.prevent="add" class="user-dashboard">
      <div class="dashboard-outer">
        <div class="upper-title-box">
          <h3>Add University</h3>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <!-- Ls widget -->
            <div class="ls-widget">
              <div class="tabs-box p-3">
                <div class="widget-content">
                  <form class="default-form">
                    <div class="row">
                      <!-- Input -->

                      <div class="form-group col-lg-12 col-md-12">
                        <label>University Name*</label>
                        <input
                          type="text"
                          v-model="name"
                          placeholder="University Name"
                          required
                        />
                      </div>

                 
                      <div class="form-group col-lg-3 col-md-12">
                        <label>Short name</label>
                        <input
                          type="text"
                          v-model="short_name"
                          placeholder="Short name"
                          required
                        />
                      </div>
                      <div class="form-group col-lg-3 col-md-12">
                        <label>University code</label>
                        <input
                          type="text"
                          v-model="code"
                          placeholder="University code"
                          required
                        />
                      </div>
                      <div class="form-group col-lg-3 col-md-12">
                        <label>Email</label>
                        <input
                          type="text"
                          v-model="email"
                          placeholder="Email"
                          required
                        />
                      </div>
                      <div class="form-group col-lg-3 col-lg-3">
                        <label>Conatact no</label>
                        <input
                          type="number"
                          v-model="contact_no"
                          placeholder="Contact"
                          required
                        />
                      </div>

                      <!-- About Company -->
                      <div class="form-group col-lg-6 col-md-12">
                        <label>Introduction</label>
                        <ckeditor
                           
                            :editor="editor"
                            v-model="introduction"
                          ></ckeditor>
                      </div>

                      <div class="form-group col-lg-6 col-md-12">
                        <label>Description</label>
                        <ckeditor
                           
                           :editor="editor"
                           v-model="description"
                         ></ckeditor>
                      </div>
                      <div class="form-group col-lg-6 col-md-12">
                        <label>Accommodation</label>
                        <ckeditor
                           
                           :editor="editor"
                           v-model="accommodation"
                         ></ckeditor>
                      </div>
                      <div class="form-group col-lg-6 col-md-12">
                        <label>School</label>
                        <ckeditor
                           
                           :editor="editor"
                           v-model="school"
                         ></ckeditor>
                      </div>

                      <div class="form-group col-lg-12 col-md-12">
                        <label>Admissions process</label>
                        <ckeditor
                           :editor="editor"
                           v-model="admissions_process"
                         ></ckeditor>
                      </div>

                    

                      <div class="form-group col-lg-3 col-md-12">
                        <label>Founded year</label>
                        <input
                          type="text"
                          v-model="founded_at"
                          placeholder="Founded year"
                          required
                        />
                      </div>

                      <div class="form-group col-lg-3 col-md-12">
                        <label>Total student</label>
                        <input
                          type="number"
                          v-model="total_student"
                          placeholder="Total student"
                          required
                        />
                      </div>


                      <div class="form-group col-lg-3 col-md-12">
                        <label>Total international Student</label>
                        <input
                          type="number"
                          v-model="international_student"
                          placeholder="Total International Student"
                          required
                        />
                      </div>

                      <div class="form-group col-lg-3 col-md-12">
                        <label>City</label>
                        <input
                          type="text"
                          v-model="city"
                          placeholder="Location"
                          required
                        />
                      </div>

                      <div class="form-group col-lg-12 col-md-12">
                        <label>Location</label>
                        <input
                          type="text"
                          v-model="location"
                          placeholder="Location"
                          required
                        />
                      </div>
                     

                      <div class="form-group col-lg-3 col-md-12">
                        <label>Total Section</label>
                        <input
                          type="number"
                          v-model="total_section"
                          placeholder="Total section"
                          required
                        />
                      </div>
                      <div class="form-group col-lg-3 col-md-12">
                        <label>Total Program</label>
                        <input
                          type="number"
                          v-model="total_program"
                          placeholder="Total program"
                          required
                        />
                      </div>

                  


                      <div class="form-group col-lg-3 col-md-12">
                        <label>Scholarship open</label>
                        <select v-model="scholarship_open" required>
                          <option disabled value="null">Select a option</option>

                          <option value="1">Enable</option>
                          <option value="0">Disable</option>
                        </select>
                      </div>

                      <div class="form-group col-lg-3 col-md-12">
                        <label>Type</label>
                        <select v-model="type" required>
                          <option disabled value="null">Select a option</option>

                          <option value="private">Private</option>
                          <option value="public">Public</option>
                        </select>
                      </div>
                      <div class="form-group col-lg-4 col-md-12">
                        <label>Category</label>
                        <select v-model="category_id" required>
                          <option disabled value="null">Select a option</option>

                          <option v-for="category in categories" :key="category.id" :value="category.id">{{ category.name }}</option>
                        </select>
                      </div>

                      <div class="form-group col-lg-5 col-md-12">
                        <label>Status</label>
                        <select v-model="status" required>
                          <option disabled value="null">Select a option</option>

                          <option value="1">Enable</option>
                          <option value="0">Disable</option>
                        </select>
                      </div>

                      <div class="form-group col-lg-6 col-md-12">
                        <label>Logo</label>
                        <input @change="logoSelect" class="form-control"
                          type="file"
                        
                          placeholder="Total program"
                          required
                        />
                      </div>
                      <div class="form-group col-lg-6 col-md-12">
                        <label>Banner Image</label>
                        <input class="form-control"
                          type="file"
                          @change="bannarSelect"
                        
                          placeholder="Total program"
                          required
                        />
                      </div>

                      <!-- Input -->
                      <div class="form-group col-lg-12 col-md-12 text-right">
                        <button type="submit" class="theme-btn btn-style-one">
                          Add university
                        </button>
                       <span class="ms-2" >{{ message }}</span> 
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Dashboard -->
  
  </div>
</template>

<script>
import axios from "axios";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  name: "EmployerPostJobView",

  data() {
    return {
      
      editor: ClassicEditor,
      category_id: null,
      categories:[],
      logo : "",
      bannar : "",
      status : null,
      message :"",
     
    };
  },

  methods: {

    logoSelect(event) {
      this.logo = event.target.files[0]; 
      },


   bannarSelect(event) {
      this.bannar = event.target.files[0];
   },

  
    add() {
      this.message = "Creating university... please wait";
      var formData = new FormData();
      formData.append("name", this.name);
      formData.append("code", this.code);
      formData.append("short_name", this.short_name);
      formData.append("email", this.email);
      formData.append("contact_no", this.contact_no);
      formData.append("introduction", this.introduction);
      formData.append("description", this.description);
      formData.append("accommodation", this.accommodation);
      formData.append("school", this.school);
      formData.append("total_student", this.total_student);
      formData.append("international_student", this.international_student);
      formData.append("founded_at", this.founded_at);
      formData.append("admissions_process", this.admissions_process);
      formData.append("location", this.location);
      formData.append("city", this.city);
      formData.append("total_section", this.total_section);
      formData.append("total_program", this.total_program);
      formData.append("scholarship_open", this.scholarship_open);
      formData.append("type", this.type);
      formData.append("category_id", this.category_id);
      formData.append("status", this.status);
      formData.append("logo", this.logo);
      formData.append("bannar", this.bannar);

 

      axios
        .post("api/admin/university", formData,
        {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }


        )
        .then((res) => {


            this.$emit("get_message", res.data.message);
            this.message = "University Created !";

          
        })
        .catch((error) => {
          console.log(error.response);
          this.$emit("get_message", "University Created Successfully");

        });
    },
   category(){
    axios.get("api/admin/category").then((res) => {

      this.categories = res.data.data;
      
    }).catch((error) => {
      console.log(error.response);
    });



   },

 



  },
  mounted: function () {
    this.category();

  },
};
</script>



<style>
.button_plus {
  position: absolute;
  width: 35px;
  height: 35px;
  background: #fff;
  cursor: pointer;
  border: 2px solid #095776;
  display: flex;

  /* Mittig */
  top: 44%;
  left: 90%;
}

.button_plus:after {
  content: "";
  position: absolute;
  transform: translate(-50%, -50%);
  height: 4px;
  width: 50%;
  background: #095776;
  top: 50%;
  left: 50%;
}

.button_plus:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #095776;
  height: 50%;
  width: 4px;
}

.button_plus:hover:before,
.button_plus:hover:after {
  background: #fff;
  transition: 0.2s;
}

.button_plus:hover {
  background-color: #095776;
  transition: 0.2s;
}
</style>