
<template>
  <div class="login-section">
    <div
      class="image-layer"
      style="background-image: url(frontend/images/background/12.jpg)"
    ></div>
    <div class="outer-box">
      <!-- Login Form -->
      <div class="login-form default-form">
        <div class="form-inner">
          <h3 class="text-center">Create an Account</h3>

          <!--Login Form-->
          <form id="sign-up-form" @submit.prevent="signUp">
            <div class="row">
              <div class="form-group col-6">
                <label>First Name</label>
                <input
                  type="text"
                  v-model="first_name"
                  placeholder="First Name"
                />
                <p v-if="firstnameerror != ''" class="mt-1 text-danger">
                  {{ firstnameerror }}
                </p>
              </div>

              <div class="form-group col-6">
                <label>Last Name</label>
                <input
                  type="text"
                  v-model="last_name"
                  placeholder="Last Name"
                />
                <p v-if="lastnameerror != ''" class="mt-1 text-danger">
                  {{ lastnameerror }}
                </p>
              </div>
            </div>

            <div class="form-group">
              <label>Email Address</label>
              <input type="email" v-model="email" placeholder="Email" />
              <p v-if="emailnerror != ''" class="mt-1 text-danger">
                {{ emailnerror }}
              </p>
            </div>
            <div class="password-group">
              <label>Password</label>
              <p class="input-group">
                <input
                  id="passInput"
                  v-model="password"
                  class="custom-input"
                  placeholder="Password"
                  type="password"
                  size="30"
                  aria-required="false"
                />
                <span
                  class="input-group-addon"
                  role="button"
                  title="veiw password"
                  id="passBtn"
                >
                  <i class="bi bi-eye-slash" aria-hidden="true"></i>
                </span>
              </p>
              <p v-if="passworderror != ''" class="mt-1 text-danger">
                {{ passworderror }}
              </p>
            </div>

            <div class="password-group mt-2">
              <label> Confirm Password</label>
              <p class="input-group">
                <input
                  id="passInput"
                  v-model="password_confirmation"
                  class="custom-input"
                  placeholder="Confirm password"
                  type="password"
                  size="30"
                  aria-required="false"
                />
                <span
                  class="input-group-addon"
                  role="button"
                  title="veiw password"
                >
                  <i class="bi bi-eye-slash" aria-hidden="true"></i>
                </span>
              </p>
              <p v-if="cpassworderror != ''" class="mt-1 text-danger">
                {{ cpassworderror }}
              </p>
            </div>

            <!-- <div class="form-group mt-2">
              <fieldset>
                <input
                  type="radio"
                  v-model="usertype"
                  value="applicant"
                  name="group1"
                />
                <label class="px-2 fw-bold">APPICATNT</label>
                <input
                  type="radio"
                  v-model="usertype"
                  value="recruiter"
                  name="group1"
                />

                <label class="px-2 fw-bold">RECRUITER</label>
              </fieldset>
              <p v-if="typeerror != ''" class="mt-1 text-danger">
                {{ typeerror }}
              </p>
            </div> -->

            <div id="loader-small">
              <span class="loading__dot_small"></span>
              <span class="loading__dot_small"></span>
              <span class="loading__dot_small"></span>
            </div>

            <div class="form-group mt-3">
              <button
                class="theme-btn btn-style-one"
                type="submit"
                name="Register"
              >
                REGISTER
              </button> 
            </div>
          </form>

          <div
            id="otpbox"
            style="border-radius: 10px"
            class="p-3 border text-center"
          >
            <h5 class="text-success">
              We send you a OTP to your mail ! Check your mail
            </h5>

            <form @submit.prevent="emailverification">
              <div class="row mt-3">
                <div class="col-9">
                  <div class="form-group">
                    <input
                      id="password-field"
                      v-model="otp"
                      type="password"
                      placeholder="Enter OTP"
                    />
                    <p v-if="otperror != ''" class="mt-1 text-danger">
                      {{ otperror }}
                    </p>
                  </div>
                </div>
                <div class="col-3 p-1">
                  <button class="btn btn-lg btn-primary">
                    <i class="las la-angle-double-right"></i>
                  </button>
                </div>
              </div>
            </form>
          </div>



          <div
            id="loginbox"
            style="border-radius: 10px"
            class="p-3 border text-center"
          >
            <h5 class="text-success mb-3">
              Your email is already Registered !
            </h5>

            <a href="/login" class="theme-button p-2 mt-3">
                   Login now !
            </a>
                
          </div>

          <!-- <div class="bottom-box">
            <div class="divider"><span>or</span></div>
            <div class="btn-box row">
              <div class="col-lg-6 col-md-12">
                <a href="#" class="theme-btn social-btn-two facebook-btn"><i class="fab fa-facebook-f"></i> Log In via Facebook</a>
              </div>
              <div class="col-lg-6 col-md-12">
                <a href="#" class="theme-btn social-btn-two google-btn"><i class="fab fa-google"></i> Log In via Gmail</a>
              </div>
            </div>
          </div> -->
        </div>
      </div>
      <!--End Login Form -->
    </div>
  </div>
</template>


<script>
import axios from "axios";

export default {
  name: "RegisterView",
  data() {
    return {
      email: "",
      password: "",
      password2: "",
      first_name: "",
      last_name: "",
    
      firstnameerror: "",
      lastnameerror: "",
      emailnerror: "",
      passworderror: "",
      cpassworderror: "",
      typeerror: "",
      usertype: "",
      otperror: "",
    };
  },
  methods: {
      signUp() {
      var loader = document.getElementById("loader-small");
      loader.style.display = "block";

      if (this.first_name == "") {
        loader.style.display = "none";

        this.firstnameerror = "Enter first name";
      } else if (this.last_name == "") {
        loader.style.display = "none";
        this.lastnameerror = "Enter last name";
      } else if (this.email == "") {
        loader.style.display = "none";
        this.emailnerror = "Enter valid email address";
      } else if (this.password == "") {
        loader.style.display = "none";
        this.passworderror = "Enter password";
      } else if (this.password.length < 8) {
        loader.style.display = "none";
        this.passworderror = "Password length will be 8";
      } else if (this.password_confirmation == "") {
        loader.style.display = "none";
        this.cpassworderror = "Enter password";
      } else if (this.password_confirmation != this.password) {
        loader.style.display = "none";
        this.cpassworderror = "Password will be same";
      } else {
       
        axios
          .post("api/auth/register", {
            email: this.email,
            password: this.password,
            password_confirmation: this.password_confirmation,
            name: this.first_name+" "+this.last_name,
            role :"student"
          
          })
          .then((res) => {
            if (res) {
              loader.style.display = "none";
              this.$router.push({ name: "login" });
            
            }
          })
          .catch((error) => {
            console.log(error.response);

            loader.style.display = "none";

            if(error.response.status==400){

              var signUpForm = document.getElementById("sign-up-form");


              var loginbox = document.getElementById("loginbox");
              loginbox.style.display = "block";
              loader.style.display = "none";

              signUpForm.style.display = "none";






            }

 if(error.response.status==401){

alert("Wrong email or password !")

}
          });
      }
    },

    emailverification() {
      var loader = document.getElementById("loader-small");
      loader.style.display = "block";
      
      if (this.otp == null) {
        this.otperror = "Enter OTP";
      } else if (this.otp.length < 6) {
        this.otperror = "Enter 6 digit OTP";
      } else {
        axios
          .post("/access/verification/", {
            email: this.email,
            verification_code: this.otp,
          })
          .then((res) => {
            if (res.status == 202) {
              alert("Verification Successfully");

              this.$router.push({ name: "login" });
            }
          })
          .catch((error) => {
            var loader = document.getElementById("loader-small");

            loader.style.display = "none";
           alert("Your otp expired or invalid")
            console.log(error.response);
          });
      }
    },
  },
};
</script>



