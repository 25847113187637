<template>
    <div>
      
  
     
  
      <!-- Dashboard -->
      <section @submit.prevent="add" class="user-dashboard">
        <div class="dashboard-outer">
          <div class="upper-title-box">
            <h3>Add Course</h3>
          </div>
  
          <div class="row">
            <div class="col-lg-12">
              <!-- Ls widget -->
              <div class="ls-widget">
                <div class="tabs-box p-3">
                  <div class="widget-content">
                    <form class="default-form">
                      <div class="row">
                        <!-- Input -->
  
                        <div class="form-group col-lg-12 col-md-12">
                          <label>Course Title</label>
                          <input
                            type="text"
                            v-model="title"
                            placeholder="Course Title"
                            required
                          />
                        </div>
  
                   
                        <div class="form-group col-lg-3 col-md-12">
                          <label>Short Title</label>
                          <input
                            type="text"
                            v-model="short_name"
                            placeholder="Short Title"
                            required
                          />
                        </div>
                        <div class="form-group col-lg-3 col-md-12">
                          <label>Course code</label>
                          <input
                            type="text"
                            v-model="code"
                            placeholder="Course code"
                            required
                          />
                        </div>
                        <div class="form-group col-lg-3 col-md-12">
                          <label>Seat</label>
                          <input
                            type="text"
                            v-model="seat"
                            placeholder="Seat"
                            required
                          />
                        </div>
                        <div class="form-group col-lg-3 col-lg-3">
                          <label>Duration</label>
                          <input
                            type="text"
                            v-model="duration"
                            placeholder="Duration"
                            required
                          />
                        </div>
  
                        <!-- About Company -->
                      
  
                        <div class="form-group col-lg-12 col-md-12">
                          <label> Course Description</label>
                          <ckeditor
                             
                             :editor="editor"
                             v-model="description"
                           ></ckeditor>
                        </div>
                  
                       
  
                     
                      
  
                        <div class="form-group col-lg-3 col-md-12">
                          <label>Fee</label>
                          <input
                            type="text"
                            v-model="fee"
                            placeholder="Fee"
                            required
                          />
                        </div>
  
               
  
           
           
  
                        <div class="form-group col-lg-5 col-md-12">
                          <label>University</label>
                          <select  v-model="university_id">
                            <option disabled value="null">Select a univeristy</option>
  
                            <option v-for="university in universites" :key="university.id" :value="university.id">{{university.name}}</option>
                          </select>
                        </div>
  
                        <div class="form-group col-lg-4 col-md-12">
                          <label>Program</label>
                          <select v-model="program_id"  >
                            <option disabled value="null">Select a program</option>
  
                            <option v-for="program in programs" :key="program.id" :value="program.id">{{program.name}}</option>
                          </select>
                        </div>
                    
                    
                        <div class="form-group col-lg-6 col-md-12">
                          <label>Logo</label>
                          <input class="form-control"
                            type="file"
                          
                            placeholder="Total program"
                            required
                          />
                        </div>
                        <div class="form-group col-lg-4 col-md-12">
                          <label>Status</label>
                          <select v-model="status"  >
                            <option disabled value="null">Select a status</option>


                            <option value="1">Enable</option>
                            <option value="0">Disable</option>
  
                          </select>
                        </div>
                 
                        <!-- Input -->
                        <div class="form-group col-lg-12 col-md-12 text-right">
                          <button type="submit" class="theme-btn btn-style-one">
                            Add course
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- End Dashboard -->
    
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
  
  export default {
    name: "EmployerPostJobView",
  
    data() {
      return {
        programs :[],
        universites :[],
        program_id:null,
        university_id:null,
        status:null,
 
        editor: ClassicEditor,
       
      };
    },
  
    methods: {
    add(){

      axios.post("api/admin/course",{
        title:this.title,
        short_title:this.short_title,
        seat:this.seat,
        duration:this.duration,
        course_description:this.course_description,
        fee:this.fee,
        university_id:this.university_id,
        program_id:this.program_id,
        status:this.status,
     

      }).then((res)=>{
        this.$emit("get_message", res.data.message);

      }).catch((error)=>{


        console.error(error.response)


      })



    },
    getProgram(){


      axios.get("api/admin/program").then((res)=>{
        
        this.programs = res.data.data;
      }).catch((error)=>{
        console.error(error.response)
      })

    },
    
    getUniversity(){


        axios.get("api/admin/university").then((res)=>{
  
        this.universites = res.data.data;
     }).catch((error)=>{
      console.error(error.response)
      })

},

  


  
  
  

    },
    mounted: function () {
      this.getProgram();
      this.getUniversity();
    },
  };
  </script>
  
  
  
  <style>
  .button_plus {
    position: absolute;
    width: 35px;
    height: 35px;
    background: #fff;
    cursor: pointer;
    border: 2px solid #095776;
    display: flex;
  
    /* Mittig */
    top: 44%;
    left: 90%;
  }
  
  .button_plus:after {
    content: "";
    position: absolute;
    transform: translate(-50%, -50%);
    height: 4px;
    width: 50%;
    background: #095776;
    top: 50%;
    left: 50%;
  }
  
  .button_plus:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #095776;
    height: 50%;
    width: 4px;
  }
  
  .button_plus:hover:before,
  .button_plus:hover:after {
    background: #fff;
    transition: 0.2s;
  }
  
  .button_plus:hover {
    background-color: #095776;
    transition: 0.2s;
  }
  </style>