<template>
  <header class="main-header header-shaddow">
       <div class="container-fluid">
         <!-- Main box -->
         <div class="main-box">
           <!--Nav Outer -->
           <div class="nav-outer">
             <div class="logo-box">
               <div class="logo"><a href="/"><img src="/images/logo/logo3.png" alt="" title=""></a></div>

               
             </div>
 
             <nav class="nav main-menu t">
               <ul class="navigation" id="navbar">
                 
                 
               </ul>
             </nav>
             <!-- Main Menu End-->
           </div>
          <div>

            <span>Profile</span>
            <span class="ms-4" >Notifications</span>



          </div>
           
         
         </div>
         
       </div>
 
       <!-- Mobile Header -->
       <div class="mobile-header">
         <div class="logo"><a href="/"><img src="/images/logo/logo3.png" alt="" title=""></a></div>


 
         <!--Nav Box-->
         <div class="nav-outer clearfix">
 
           <div class="outer-box">
             <!-- Login/Register -->
             <div class="login-box">
               <a href="login-popup.html" class="call-modal"><span class="icon-user"></span></a>
             </div>
 
             <button id="toggle-user-sidebar"><img src="images/resource/company-6.png" alt="avatar" class="thumb"></button>
             <a href="#nav-mobile" class="mobile-nav-toggler navbar-trigger"><span class="flaticon-menu-1"></span></a>
           </div>
         </div>
 
       </div>
 
       <!-- Mobile Nav -->
       <div id="nav-mobile"></div>
     </header>
 </template>
 
 <script>
 import vcookie from 'vue-cookie';
 export default {
    name:"DashHeaderView",
 
    data() {
     
     return {
       name:"",
       logo:""

           
 
     }
   },
 
   methods:{
   showprofile(){
 
     const user = JSON.parse(vcookie.get('user'));
 
     if(user){
      this.name=user.user.first_name +" "+ user.user.last_name

      if(user.user.profile_pic!=null){
        this.logo='https://api.hive4work.co.uk/'+user.user.profile_pic
      }else{
        this.logo='images/user.png'
          
      }
  

     }
       
 
 
   },
   logout(){
 
 const user = JSON.parse(vcookie.get('user'));
 
 if(user){
    
  vcookie.delete('user')
 
  this.$router.push({name:'login'})

 
 
 
 
 }
  
 
 
 }
 
 
 
   },
   mounted: function(){
     }
 
 }
   
 
 </script>
 
 <style>
 
 </style>