<template>
    
  <div>
       <!-- Sidebar Backdrop -->

<!-- End User Sidebar -->

 <!-- Dashboard -->
    <section class="user-dashboard">
      <div class="dashboard-outer">
        <div class="upper-title-box">
          <h3>Manage Program</h3>
      
        </div>

        <div class="row">
          <div class="col-lg-12">
            <!-- Ls widget -->
            <div class="ls-widget">
              <div class="tabs-box">
                <div class="widget-title">
                  <h4>Program List</h4>

                  <!-- <div class="chosen-outer">
                    <select class="chosen-select">
                      <option>Last 6 Months</option>
                      <option>Last 12 Months</option>
                      <option>Last 16 Months</option>
                      <option>Last 24 Months</option>
                      <option>Last 5 year</option>
                    </select>
                  </div> -->
               <router-link :to="{ name: 'admin-add-program' }" class="theme-btn btn">Add Program</router-link>
                </div>

                <div class="widget-content">
                  <div class="table-outer">
                    <table class="default-table manage-job-table">
                      <thead>
                        <tr>
                          <th>name</th>
                         
                            <th>Logo</th>
      
                            <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr v-for=" program in programs" :key="program"  >
                          <td>
                            <h6>{{ program.name }}</h6>
                           
                          </td>

                    
                          <td><img style="height: 50px;width: 50px;" :src="imageurl+program.logo" alt=""></td>
                         
              
                          <td class="status">{{program.status}}</td>
                          <td>
                            <div class="option-box">
                              <ul class="option-list">
                                <li><button @click="editprogram(program.id)" data-text="Reject Aplication"><span class="la la-pencil"></span></button></li>
                                <li><button @click="deleteprogram(program.id)" data-text="Delete Aplication"><span class="la la-trash"></span></button></li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                       
                     
                     
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
    </section>
    <!-- End Dashboard -->

<!-- End Dashboard -->


</div>
</template>

<script>
import axios from 'axios';
import Constant from "@/interceptor/Constant.js";

export default {

    name:'EmployerManageJobView',

    data(){
      return{

        programs:[],
       imageurl : Constant.filebaseurl+"/image/university/",


      }

    },


methods: {
  

  getProgram() { axios.get("api/admin/program",

   ).then( res => {

  
     this.programs=res.data.data

     if(res.status==200){

      console.log("Data received")
   

     }
   
   }

 )
.catch(error => {
console.log(error.response)
});
 
},

editprogram(id){
    this.$router.push({name:'update-program',query:{id:id}})
},
  
deleteprogram(id){
  axios
  .delete("api/admin/program/" + id)
  .then((res) => {
    if (res.status == 200) {
      this.$emit("get_message", res.data.message);
      this.getProgram();
    }
  })
  .catch((error) => {
    console.log(error.response);
   
  });

},

},

mounted:function(){
  this.getProgram()
}

  
}
</script>

<style>

</style>