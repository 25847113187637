<template>
  <div>
    <header class="main-header">
      <!-- Main box -->
      <div class="main-box">
        <!--Nav Outer -->
        <div class="nav-outer">
          <div class="logo-box">
            <div class="logo">
              <a href="/"><img src="images/logo/logo3.png" alt="" title="" /></a>
            </div>
          </div>

    <nav class="nav main-menu">
      <ul class="navigation" id="navbar">
        <li><a href="/">HOME</a></li>
        <!--li><a href="contact.html">ABOUT</a></li-->
        <li><router-link :to="{ name: 'programs' }">PROGRAMS</router-link></li>
        <li><router-link :to="{ name: 'universitylist' }">UNIVERSITIES</router-link></li>
        <!-- <li><a href="/candidates">CV search</a></li> -->
        <li><router-link :to="{ name: 'contact' }">CONTACT US</router-link></li>
        
        
        


              <!-- Only for Mobile View -->
              <li class="mm-add-listing">
                <!-- <a href="add-listing.html" class="theme-btn btn-style-one"
                  >Job Post</a
                > -->
                <span>
                  <span class="contact-info">
                    <span class="phone-num"
                      ><span>Call us</span
                      ><a href="tel:+44 7495 587992">+44 7495 587992</a></span
                    >
                    <span class="address"
                      >128-130 the Grove,Startford,London,England,E15 1NS
                    </span>
                    <a href="mailto:support@hive4works.com" class="email"
                      >support@hive4work.co.uk</a
                    >
                  </span>
                  <span class="social-links">
                    <a href="#"><span class="fab fa-facebook-f"></span></a>
                    <a href="#"><span class="fab fa-twitter"></span></a>
                    <a href="#"><span class="fab fa-instagram"></span></a>
                    <a href="#"><span class="fab fa-linkedin-in"></span></a>
                  </span>
                </span>
              </li>
            </ul>
          </nav>
          <!-- Main Menu End-->
        </div>

        <div class="outer-box">
          <!-- Add Listing -->
          <!-- <a href="candidate-dashboard-cv-manager.html" class="upload-cv text-white"> UPLOAD YOUR CV</a> -->
          <!-- Login/Register -->
          <div class="btn-box">
            <div v-if="name != ''" class="dropdown dashboard-option">
              <a
                class="dropdown-toggle"
                role="button"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                <img height="20px" width="20px" v-if="logo != ''"
                  :src="logo"
                  alt="avatar"
                  class="thumb"
                />
                <span class="name text-grey">{{ name }}</span>
              </a>
              <ul class="dropdown-menu">
                <!--li class="active"><a href="dashboard.html"> <i class="la la-home"></i> Dashboard</a></li>
                 <li><a href="dashboard-company-profile.html"><i class="la la-user-tie"></i>Company Profile</a></li>
                 <li><a href="dashboard-post-job.html"><i class="la la-paper-plane"></i>Post a New Job</a></li>
                 <li><a href="dashboard-manage-job.html"><i class="la la-briefcase"></i> Manage Jobs </a></li>
                 <li><a href="dashboard-applicants.html"><i class="la la-file-invoice"></i> All Applicants</a></li>
                 <li><a href="dashboard-resumes.html"><i class="la la-bookmark-o"></i>Shortlisted Resumes</a></li>
                 <li><a href="dashboard-packages.html"><i class="la la-box"></i>Packages</a></li>
                 <li><a href="dashboard-messages.html"><i class="la la-comment-o"></i>Messages</a></li>
                 <li><a href="dashboard-resume-alerts.html"><i class="la la-bell"></i>Resume Alerts</a></li-->
              
                <li>
                  <a @click.prevent="dashboard"
                    ><i class="la la-user-alt"></i>Dashboard</a
                  >
                </li>
                <li>
                  <a @click.prevent="logout" href="#"
                    ><i class="la la-sign-out"></i>Logout</a
                  >
                </li>
                <!--li><a href="index.html"><i class="la la-trash"></i>Delete Profile</a></li-->
              </ul>
            </div>

            <a v-else href="/login" class="theme-button2"
              >LOGIN/REGISTER</a
            >
            <a
              v-if="name != ''"
              href="#"
              @click="dashboard"
              class="theme-button"
              >Dashboard</a
            >
          </div>
        </div>
      </div>

      <!-- Mobile Header -->
      <div class="mobile-header">
        <div class="logo">
          <a href="/"
            ><img class="mt-2" src="/images/logo/logo3.png" alt="" title=""
          /></a>
        </div>

        <!--Nav Box-->
        <div class="nav-outer clearfix">
          <div class="outer-box text-black">
            <!-- Login/Register -->
            <div class="login-box">
              <a class="text-black" href="/login"
                ><span style="font-size: 120%" class="las la-user mt-2"></span
              ></a>
            </div>

            <a href="#nav-mobile" class="mobile-nav-toggler text-black"
              ><span class="flaticon-menu-1"></span
            ></a>
          </div>
        </div>
      </div>

      <!-- Mobile Nav -->

      <div id="nav-mobile"></div>
    </header>
  </div>
</template>

<script>
import vcookie from "vue-cookie";

export default {
  name: "HeaderView",

  data() {
    return {
      name: "",
      logo:""
    };
  },

  methods: {
    showprofile() {
      const user = JSON.parse(vcookie.get("user"));

      if (user) {
        this.name = user.name;

     
        this.logo='images/user.png'
          
      
  
      
      } 

      
    },
    logout() {
      const user = JSON.parse(vcookie.get("user"));

      if (user) {
        vcookie.delete("user");

        this.$router.push({ name: "home" });
        location.reload();
      }
    },

    dashboard() {
       
      const user = JSON.parse(vcookie.get("user"));

      if (user) {

        if (user.role == "admin") {
          this.$router.push({ name: "admin-dashboard" });
        }
        if (user.role == "student") {
          this.$router.push({ name: "student-dashboard" });
        }
      
      }else{
        this.$router.push({ name: "login" });


      }
    },
  },
  mounted: function () {

    this.showprofile();
  },
};
</script>

<style>
</style>