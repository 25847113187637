<template>
    <!--Page Title-->
    <div>
        
      <br /><br /><br />
      <section class="page-title style-two">
        <div class="auto-container">
          <div class="row justify-content-center">
            <div class="col-lg-12">
              <div class="sec-title text-center">
                <h2 class="text-white" style="font-size: 45px; line-height: 80px font-family: tungstenw05-medium, Oswald, sans-serif; font-weight: 500; color:#fffff">{{ university_name }}</h2>
                <div class="text text-white" style="font-size: 18px; line-height: 80px font-family: tungstenw05-medium, Oswald, sans-serif; font-weight: 500; color:#fffff">
                  Choose your desire program !
                </div>
              </div>
            </div>
          </div>
  
          <div style="background-color: white;border-radius: 10px;" >
  
           <div class="row">
  
            <div class="col-lg-10" style="margin:0px;padding: 0px;z-index: 10;">
  
              <div  class="form-group text-left px-5 py-3 ">
                  <span style="color:grey" class="icon flaticon-search-1"></span>
                  <input style="border-radius: 10px;width: 80%;color: grey;padding-left: 20px;"
                    type="text"
                    @input="showjob($event.target.value)"
                    name="field_name"
                    placeholder=" title, keywords, or course"
  
                    
                  />
  
                 <span style="height: 100%;background-color: red;" ></span>
                </div>
  
            </div>
            <div  id="find-job" class="col-2 pt-3" style="background-color: #3f4a5a;margin:0px;padding: 0px;border-radius: 0px 10px 10px 0px;color:white" >
  
              <span >FIND PROGARM</span>
              
            </div>
  
  
           </div>
          
  
  
  
            
  
  
  
          </div>
  
           
          
          
         
        </div>
      </section>
      <!--End Page Title-->
  
      <!-- Listing Section -->
      <section class="ls-section">

        
        <div class="auto-container">


          <div class="row">

<div class="col-lg-6">

  <span style="font-size: 15px;border: 1px solid #3f4a5a;border-radius: 10px;padding: 10px 20px;">Bachelor</span>
  <span style="font-size: 15px;border: 1px solid #3f4a5a;border-radius: 10px;padding: 10px 20px;margin-left: 10px;">Masters</span>
  <span style="font-size: 15px;border: 1px solid #3f4a5a;border-radius: 10px;padding: 10px 20px;margin-left: 10px;">Phd</span>
  <span style="font-size: 15px;border: 1px solid #3f4a5a;border-radius: 10px;padding: 10px 20px;margin-left: 10px;">MBBS</span>


</div>

<div style="text-align: end;" class="col-lg-6 ">

  <span style="font-size: 15px;border: 1px solid #3f4a5a;border-radius: 10px;padding: 10px 20px;">Total : {{courses.length}}</span>
  





</div>



</div>


          
   

          
  
          <div class="row mt-4">
            <!-- Content Column -->
            <div class="content-column col-lg-12">
              <div class="ls-outer">
              
  
                <div class="row">
                  <!-- Job Block -->
                  <div
                 
                    v-for="course in courses"
                    :key="course.id"
                    class="job-block  col-lg-6"
                  >
                    <div   @click="openjob(course.uuid)" class="inner-box text-left">
                      <div class="content">
                        <span v-if="course.logo!=null"  class="company-logo"
                          ><img :src="course.logo" alt="Logo"/></span>
                          <div class="row">
                            <div class="col-9">
                              <h4 class="">{{course.title}}</h4>



                            </div>
                            <div class="col-3">
                              <button @click="apply(course.id)" class="theme-btn btn">Apply</button>


                            </div>


                          </div>

                        <div class="row">

                          <div class="col-3">

                            <p style="font-size: 70% ;" >Next Start Date</p>
                            <p style="font-size: 80% ;font-weight: bold;" >Now</p>
                          </div>
                          <div class="col-3">

                            <p style="font-size: 70% ;" >Tution</p>
                            <p style="font-size: 80% ;font-weight: bold;" >{{ course.fee }} RMB</p> 
                        </div>
                         <div class="col-2">

                            <p style="font-size: 70% ;" >Seat</p>
                            <p style="font-size: 80% ;font-weight: bold;" >{{ course.seat }} </p> 
                        </div>

                        <div class="col-4">

<p style="font-size: 70% ;" >Tution</p>
<p style="font-size: 80% ;font-weight: bold;" >{{ course.duration }} </p> 
</div>
                         


                        </div>
                       
                        <!-- <ul class="job-other-info">
                          <li class="time">Full time</li>
                          <li class="privacy">Private</li>
                          <li class="required">Urgent</li>
                        </ul> -->
                        <!-- <button class="bookmark-btn">
                          <span class="flaticon-bookmark"></span>
                        </button> -->
                      </div>
                    </div>
                  </div>
                </div>
  
                <!-- Pagination -->
                <nav class="ls-pagination">
                  <ul>
                    <li class="prev">
                      <a href="#"><i class="fa fa-arrow-left"></i></a>
                    </li>
                    <li><a href="#">1</a></li>
                    <li><a href="#" class="current-page">2</a></li>
                    <li><a href="#">3</a></li>
                    <li class="next">
                      <a href="#"><i class="fa fa-arrow-right"></i></a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!--End Listing Page Section -->
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import vcookie from "vue-cookie";


  export default {
    data() {
      return {
        
        courses: [],
        university_id : "",
      university_name :"",
        
      };
    },
  
    methods: {
      courseListByUniversity(id) {
        axios
          .get("api/web/course/university/"+id)
          .then((res) => {
           
              this.courses = res.data.data;
  
            
          })
          .catch((error) => {
            console.log(error.response);
          });
      },
  
      openjob(uuid) {
        if (uuid != null) {
          this.$router.push({ name: "job-single", query: { uuid: uuid } });
        }
      },
  
   

      
      apply(id){

        if(vcookie.get("user") != null){

          if(JSON.parse(vcookie.get("user")).role == "student"){

        axios.post("api/web/apply",{
          user_id : JSON.parse(vcookie.get("user")).id,
          university_id : this.university_id,
        course_id : id,
        description : "description"


       }).then((res) => {

        if(res){
          this.$emit("get_message", "Applied to Course Successfully");

        }
       
       }).catch((error) => {
         console.log(error.response);
       });




            
          }else{

            var route_name_stu = this.$route.name

       

this.$router.push({ name: "login" , query: { route_name: route_name_stu, id: this.university_id } });



          }


      
          
        }else{

          var route_name = this.$route.name



       

this.$router.push({ name: "login" , query: { route_name: route_name, id: this.university_id } });


       

        }


  
     
      },
  
  
    
    },
  
    mounted: function () {

      var university_id = this.$route.query.id;
      this.university_id = university_id;
      this.university_name = this.$route.query.name;
      this.courseListByUniversity(university_id);
    },
  };
  </script>
  
  <style>
  </style>