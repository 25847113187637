<template>
    
  <div>
       <!-- Sidebar Backdrop -->

<!-- End User Sidebar -->

 <!-- Dashboard -->
    <section class="user-dashboard">
      <div class="dashboard-outer">
        <div class="upper-title-box">
          <h3>Manage Course</h3>
      
        </div>

        <div class="row">
          <div class="col-lg-12">
            <!-- Ls widget -->
            <div class="ls-widget">
              <div class="tabs-box">
                <div class="widget-title">
                  <h4>Course List</h4>

                  <!-- <div class="chosen-outer">
                    <select class="chosen-select">
                      <option>Last 6 Months</option>
                      <option>Last 12 Months</option>
                      <option>Last 16 Months</option>
                      <option>Last 24 Months</option>
                      <option>Last 5 year</option>
                    </select>
                  </div> -->
               <router-link :to="{ name: 'admin-add-course' }" class="theme-btn btn">Add course</router-link>
                </div>

                <div class="widget-content">
                  <div class="table-outer">
                    <table class="default-table manage-job-table">
                      <thead>
                        <tr>
                          <th>name</th>
                          <th>Program</th>
                          <th>University</th>
                         
      
                            <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr v-for=" course in courses" :key="course"  >
                          <td>
                            <h6>{{ course.title }}</h6>
                           
                          </td>
                          <td class="status">{{course.program_id}}</td>
                          <td class="status">{{course.university_id}}</td>



                    
                         
              
                          <td class="status">{{course.status}}</td>
                          <td>
                            <div class="option-box">
                              <ul class="option-list">
                                <li><button data-text="Reject Aplication"><span class="la la-pencil"></span></button></li>
                                <li><button @click="deletecourse(course.id)" data-text="Delete Aplication"><span class="la la-trash"></span></button></li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                       
                     
                     
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
    </section>
    <!-- End Dashboard -->

<!-- End Dashboard -->


</div>
</template>

<script>
import axios from 'axios';
import Constant from "@/interceptor/Constant.js";

export default {

    name:'EmployerManageJobView',

    data(){
      return{

        courses:[],
       imageurl : Constant.filebaseurl+"/image/university/",


      }

    },


methods: {
  

  getCourse() { axios.get("api/admin/course",

   ).then( res => {

  
     this.courses=res.data.data

     if(res.status==200){

      console.log("Data received")
   

     }
   
   }

 )
.catch(error => {
console.log(error.response)
});
 
},
deletecourse(id){
  axios
  .delete("api/admin/course/" + id)
  .then((res) => {
    if (res.status == 200) {
      this.$emit("get_message", res.data.message);
      this.getCourse();
    }
  })
  .catch((error) => {
    console.log(error.response);
  });



},

},


mounted:function(){
  this.getCourse()
}

  
}
</script>

<style>

</style>