<template>
    
    <div>
         <!-- Sidebar Backdrop -->
  
  <!-- End User Sidebar -->
  
   <!-- Dashboard -->
      <section class="user-dashboard">
        <div class="dashboard-outer">
          <div class="upper-title-box">
            <h3>Applied Program</h3>
        
          </div>
  
          <div class="row">
            <div class="col-lg-12">
              <!-- Ls widget -->
              <div class="ls-widget">
                <div class="tabs-box">
                  <div class="widget-title">
                    <h4>Application</h4>
  
                    <!-- <div class="chosen-outer">
                      <select class="chosen-select">
                        <option>Last 6 Months</option>
                        <option>Last 12 Months</option>
                        <option>Last 16 Months</option>
                        <option>Last 24 Months</option>
                        <option>Last 5 year</option>
                      </select>
                    </div> -->
                 <router-link :to="{ name: 'universitylist' }" class="theme-btn btn">Apply</router-link>
                  </div>
  
                  <div class="widget-content">
                    <div class="table-outer">
                      <table class="default-table manage-job-table">
                        <thead>
                          <tr>
                            <th>University name</th>
                              <th>Course</th>
                              <th>Program</th>
                              <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
  
                        <tbody>
                          <tr v-for=" application in applications" :key="application"  >
                            <td>
                              <h6>{{ application.university.name }}</h6>
                             
                            </td>
  
                            <td>{{ application.course.title }}</td>
                            <td>{{ application.course.program_id }}</td>
                            <td class="applied"><a href="#">Pending</a></td>
                           
                            <td>
                              <div class="option-box">
                                <ul class="option-list">
                                  <li><button @click.prevent="edit(application.id)" data-text="Reject Aplication"><span class="la la-pencil"></span></button></li>
                                  <li><button @click.prevent="delete_uv(application.id)" data-text="Delete  Aplication"><span class="la la-trash"></span></button></li>
                                </ul>
                              </div>
                            </td>
                          </tr>
                         
                       
                       
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
  
  
          </div>
        </div>
      </section>
      <!-- End Dashboard -->
  
  <!-- End Dashboard -->
  
  
  </div>
  </template>
  
  <script>
  import axios from 'axios';
  import Constant from "@/interceptor/Constant.js";
  import vcookie from "vue-cookie";

  
  
  

  export default {
  
      name:'EmployerManageJobView',
  
  
      data(){
        return{
  
         applications:[],
         imageurl : Constant.filebaseurl+"/image/university/",

         user_id : null,
  
  
        }
  
      },
  
  
  methods: {
    
  

    getApplication(id) { 
        
        axios.get("api/web/application/list/"+id,
  
     ).then( res => {
  
    
       this.applications=res.data.data
  
       if(res.status==200){
  
        console.log("Data received")
     
  
       }
     
     }
  
   )
  .catch(error => {
  console.log(error.response)
  });
   
  },
  
  edit(id) {
        if (id != null) {
          this.$router.push({ name: "update-university", query: { id: id } });
        }
      },
  

      delete_uv(id) {
    axios
      .delete("api/admin/university/" + id)
      .then((res) => {
        if (res.status == 200) {
          this.$emit("get_message", res.data.message);
          this.getUniversity();
        }
      })
      .catch((error) => {
        console.log(error.response);
      });
   
   
  },
  
  
  
  },
  
  
  
  mounted:function(){

    this.user_id = JSON.parse(vcookie.get("user")).id
    this.getApplication(this.user_id)

  }
  
    
  }
  </script>
  
  <style>
  
  </style>