<template>
    <!--Page Title-->
    <div>
      <br /><br /><br />
      <section class="page-title style-two">
        <div class="auto-container">
          <div class="row justify-content-center">
            <div class="col-lg-5">
              <div class="sec-title text-center">
                <h2 class="text-white" style="font-size: 45px; line-height: 80px font-family: tungstenw05-medium, Oswald, sans-serif; font-weight: 500; color:#fffff">Find your dream university!</h2>
                <div class="text text-white" style="font-size: 18px; line-height: 80px font-family: tungstenw05-medium, Oswald, sans-serif; font-weight: 500; color:#fffff">
                  Are you looking for a program! Please find your desired one from
                  hundreds new university adding daily in pandaxue
                </div>
              </div>
            </div>
          </div>
  
          <div style="background-color: white;border-radius: 10px;" >
  
           <div class="row">
  
            <div class="col-lg-10" style="margin:0px;padding: 0px;z-index: 10;">
  
              <div  class="form-group text-left px-5 py-3 ">
                  <span style="color:grey" class="icon flaticon-search-1"></span>
                  <input style="border-radius: 10px;width: 80%;color: grey;padding-left: 20px;"
                    type="text"
                    @input="showjob($event.target.value)"
                    name="field_name"
                    placeholder=" title, keywords, or university"
  
                    
                  />
  
                 <span style="height: 100%;background-color: red;" ></span>
                </div>
  
            </div>
            <div  id="find-job" class="col-2 pt-3" style="background-color: #3f4a5a;margin:0px;padding: 0px;border-radius: 0px 10px 10px 0px;color:white" >
  
              <span >FIND UNIVERSITY</span>
              
            </div>
  
  
           </div>
          
  
  
  
            
  
  
  
          </div>
  
           
          
          
         
        </div>
      </section>
      <!--End Page Title-->
  
      <!-- Listing Section -->
      <section class="ls-section">
        <div class="auto-container">
          <div class="filters-backdrop"></div>
  
          <div class="row">
            <!-- Content Column -->
            <div class="content-column col-lg-12">
              <div class="ls-outer">
                <!-- ls Switcher -->
                <div class="ls-switcher">
                  <div class="showing-result">
                    <!-- <div class="top-filters">
                      <div class="form-group">
                        <select class="chosen-select">
                          <option>Job Type</option>
                          <option>Full Time</option>
                          <option>Part Time</option>
                          <option>Contractual</option>
                          <option>Internship</option>
                        </select>
                      </div>
  
                      <div class="form-group">
                        <select class="chosen-select">
                          <option>Date Posted</option>
                          <option>Today's Job</option>
                          <option>This week</option>
                          <option>Last week</option>
                          <option>This Month</option>
                          <option>Last 6 months</option>
                          <option>This year</option>
                        </select>
                      </div>
  
                      <div class="form-group">
                        <select class="chosen-select">
                          <option>Experience Level</option>
                          <option>No Experience</option>
                          <option>1 year Experience</option>
                          <option>1-3 years Experience</option>
                          <option>3-6 years Experience</option>
                          <option>6-10 years Experience</option>
                          <option>10+ Years Experience</option>
                        </select>
                      </div>
  
                      <div class="form-group">
                        <select class="chosen-select">
                          <option>Salary estimate</option>
                          <option>Upto 5$/hour</option>
                          <option>Upto 10$/hour</option>
                          <option>Upto 15$/hour</option>
                          <option>Upto 20$</option>
                          <option>Upto 2000$/monthly</option>
                          <option>2000$/monthly</option>
                        </select>
                      </div>
                    </div> -->
                  </div>
  
                  <!-- <div class="sort-by">
                    <select class="chosen-select">
                      <option>Job Type</option>
                      <option>Full Time</option>
                      <option>Part Time</option>
                      <option>Contractual</option>
                      <option>Internship</option>
                    </select>
  
                    <select class="chosen-select">
                      <option>Show 10</option>
                      <option>Show 20</option>
                      <option>Show 30</option>
                      <option>Show 40</option>
                      <option>Show 50</option>
                      <option>Show 60</option>
                    </select>
                  </div> -->
                </div>

                <p>Search result for : <strong>{{ search_key }}</strong></p>
  
                <div class="row mt-4">
                  <!-- Job Block -->
                  <div
                    v-for="u in ulist"
                    :key="u.id"
                    class="job-block col-lg-6"
                  >
                    <div @click="openjob(u.uuid)" class="inner-box">
                      <div class="content">
                        <span v-if="u.logo!=null"  class="company-logo"
                          ><img :src="u.logo" alt="Logo"/></span>
                        <h4 class="c-text-left">{{u.name}}</h4>
                        <ul class="job-info">
                          <li>
                            <span class="icon flaticon-briefcase"></span
                            >{{  }}
                          </li>
                          <li>
                            <span class="icon flaticon-map-locator"></span
                            >{{  }}
                          </li>
                          <li>
                            <span class="icon flaticon-clock-3"></span> Location
                            {{  }}
                          </li>
                          <li>
                            <span class="icon flaticon-money"></span> 
                          </li>
                        </ul>
                        <!-- <ul class="job-other-info">
                          <li class="time">Full time</li>
                          <li class="privacy">Private</li>
                          <li class="required">Urgent</li>
                        </ul> -->
                        <button class="bookmark-btn">
                          <span class="flaticon-bookmark"></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
  
                <!-- Pagination -->
                <nav class="ls-pagination">
                  <ul>
                    <li class="prev">
                      <a href="#"><i class="fa fa-arrow-left"></i></a>
                    </li>
                    <li><a href="#">1</a></li>
                    <li><a href="#" class="current-page">2</a></li>
                    <li><a href="#">3</a></li>
                    <li class="next">
                      <a href="#"><i class="fa fa-arrow-right"></i></a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!--End Listing Page Section -->
    </div>
  </template>
  
  <script>
  import axios from "axios";
  export default {
    data() {
      return {
       
        ulist: [],
        search_key: this.$route.query.key
        
      };
    },
  
    methods: {
      unlist(search_key) {
        axios
          .get("api/web/university/search/"+search_key)
          .then((res) => {
           
              this.ulist = res.data.data;
  
            
          })
          .catch((error) => {
            console.log(error.response);
          });
      },
  
  

  
  
  
    
    },
  
    mounted: function () {
      var search_key = this.$route.query.key
      this.unlist(search_key);
    },
  };
  </script>
  
  <style>
  </style>