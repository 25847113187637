<template>
    <div class="login-section">
      <div
        class="image-layer"
        style="background-image: url(/frontend/images/background/13.jpg)"
      ></div>
      <div class="outer-box">
        <!-- Login Form -->
        <div class="login-form default-form">
          <div class="form-inner">
            <h3 class="text-center">LOGIN TO PANDAXUE ADMIN</h3>
            <!--Login Form-->
            <form id="login-form" @submit.prevent="login">
              <div class="form-group">
                <label>Email</label>
                <input type="email" v-model="email" placeholder="Email" />
  
                <p v-if="emailerror != ''" class="mt-1 text-danger">
                  {{ emailerror }}
                </p>
              </div>
  
              <div class="form-group">
                <label>Password</label>
                <input
                  id="password-field"
                  type="password"
                  v-model="password"
                  placeholder="Password"
                />
                <p v-if="passworderror != ''" class="mt-1 text-danger">
                  {{ passworderror }}
                </p>
              </div>
  
              <div class="form-group">
                <div class="field-outer">
                  <div class="input-group checkboxes square">
                    <input
                      type="checkbox"
                      name="remember-me"
                      value=""
                      id="remember"
                    />
                    <label for="remember" class="remember"
                      ><span class="custom-checkbox"></span> Remember me</label
                    >
                  </div>
                  <a href="#" class="pwd">Forgot password?</a>
                </div>
              </div>
  
              <div class="form-group">
                <button class="theme-btn btn-style-one" type="submit">
                  Log In
                </button>
              </div>
  
            </form>
  
  
  
            <div class="bottom-box">
              <div id="loader-small">
                <span class="loading__dot_small"></span>
                <span class="loading__dot_small"></span>
                <span class="loading__dot_small"></span>
              </div>
         
              <!-- <div class="divider"><span>or</span></div>
                <div class="btn-box row">
                  <div class="col-lg-6 col-md-12">
                    <a href="#" class="theme-btn social-btn-two facebook-btn"><i class="fab fa-facebook-f"></i> Log In via Facebook</a>
                  </div>
                  <div class="col-lg-6 col-md-12">
                    <a href="#" class="theme-btn social-btn-two google-btn"><i class="fab fa-google"></i> Log In via Gmail</a>
                  </div>
                </div> -->
            </div>
  
  
            <div
              id="otpbox"
              style="border-radius: 10px"
              class="p-3 border text-center"
            >
              <h5 class="text-success">
                We send you a OTP to your mail ! Check your mail
              </h5>
  
              <form @submit.prevent="emailverification">
                <div class="row mt-3">
                  <div class="col-9">
                    <div class="form-group">
                      <input
                        id="password-field"
                        v-model="otp"
                        type="password"
                        placeholder="Enter OTP"
                      />
                      <p v-if="otperror != ''" class="mt-1 text-danger">
                        {{ otperror }}
                      </p>
                    </div>
                  </div>
                  <div class="col-3 p-1">
                    <button class="btn btn-lg btn-primary">
                      <i class="las la-angle-double-right"></i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <!--End Login Form -->
      </div>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import vcookie from "vue-cookie";
  
  export default {
    name: "AdminLoginView",
  
    data() {
      return {
        user: {},
        emailerror: "",
        passworderror: "",
  
        email: "",
        password: "",
      };
    },
    methods: {
      login() {
       
  
        if (this.email == "") {
          this.emailerror = "Enter email Address";
        } else if (!this.email.includes("@")) {
          this.emailerror = "Enter valid email Address";
        } else if (this.password == "") {
          this.passworderror = "Enter password";
        } else if (this.password.length < 2) {
          this.passworderror = "Enter 8 digit password";
        } else {

            var loader = document.getElementById("loader-small");
            loader.style.display = "block";
  
            axios.post("api/auth/login",


            {

              email: this.email,
              password: this.password,


            }
  
          
            ).then((response) => {

                if(response){

                    vcookie.set("user", JSON.stringify(response.data.user), "1d");

                    var loader = document.getElementById("loader-small");
                    loader.style.display = "none";
  

                    this.user=response.data.user
                    if(this.user.role=="admin"){

                        this.$router.push({ name: "admin-dashboard" });
                    }



                }




            }).catch((error) => {

                var loader = document.getElementById("loader-small");
                loader.style.display = "none";

               this.passworderror="Invalid email or password"

               console.log(error.response)

                 
                




            })



                     
  
        }
      },
  
  
  
  
    },
  };
  </script>
  
  <style>
  </style>